/*
  Flaticon icon font: Flaticon
  Creation date: 05/06/2018 03:51
  */

@font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon/font/Flaticon.eot");
    src: url("../fonts/flaticon/font/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../fonts/flaticon/font/Flaticon.woff") format("woff"),
    url("../fonts/flaticon/font/Flaticon.ttf") format("truetype"),
    url("../fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-parking:before {
    content: "\f103";
}

.flaticon-pool:before {
    content: "\f101";
}

.flaticon-snack:before {
    content: "\f102";
}

.flaticon-taxi:before {
    content: "\f103";
}

.flaticon-credit-card:before {
    content: "\f104";
}

.flaticon-hotel:before {
    content: "\f105";
}

.flaticon-double-bed:before {
    content: "\f106";
}

.flaticon-dinner:before {
    content: "\f107";
}

.flaticon-customer-service:before {
    content: "\f108";
}

.flaticon-wifi:before {
    content: "\f109";
}
